import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from '../store';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as utils from '../utils';
import '../../theme/light.scss';
import { Layout } from 'element-react';

class Footer extends PureComponent {

	render () {

		const {
			common
		} = this.props;

		if (!common) {
			return null;
		}

		let newCommon = common.toJS();

		return (
			<Layout.Row type="flex" className="footerView content">
	        	<Layout.Col span="4"></Layout.Col>
	        	<Layout.Col span="16">
					<div id={this.props.id}>
						<Row>
							<Col xs={12} sm={6}>
								<Row>
									<Col>
										<span className='footTitle wow slideInUp'>{newCommon.siteNavDD}</span>
										<div className='wow slideInUp'>
											{
												newCommon.list.map((item, index) => {
													if (index === 0) {
														return (
															<a href='/#' className='footLink' onClick={utils.handleNavItemClick.bind(this, index)} key={item.link}>网站{item.link}</a>
														)
													} else if (item.url.indexOf('#') > -1) {
														return (
															<Link to={item.url} key={item.url}>
																<div className='footLink' onClick={utils.handleNavItemClick.bind(this, index, item.url.substring(2, item.url.length))}>{item.link}</div>
															</Link>
														)
													} else {
														return (
															<Link to={item.url} key={item.url} className='footLink' onClick={utils.handleNavItemClick.bind(this, index)}>
																{item.link}
															</Link>
														)
													}
												})
											}
										</div>
									</Col>
									<Col>
										<span className='footTitle wow slideInUp'>{newCommon.linkUSDD}</span>
										<div className='wow slideInUp'>
											{
												newCommon.linkList.map((item, index) => {
													return (
														<a className='footLink' key={item.dt}>{item.dt}</a>
													)
												})
											}
										</div>
									</Col>
								</Row>
							</Col>
							<Col xs={12} sm={6} className='aboutView'>
								<Row>
									{
										newCommon.followList.map((item, index) => {
											return (
												<div key={item.url}>
													<span className='footTitle wow slideInUp'>{item.title}</span>
													<img src={item.url} className='codeIcon wow slideInUp' alt='weibocodeIcon'/>
													<div className='iconDesc wow slideInUp'>{item.name}</div>
												</div>
											)
										})
									}
								</Row>
							</Col>
						</Row>
						<div className='siteDesc'><span>{newCommon.copyRight0} &copy; {newCommon.copyRight1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                        <div className='siteDesc'><span><a className='siteDesc1' href="https://beian.miit.gov.cn/" target="_blank">{newCommon.copyRight2}</a></span></div>
                        <div className='siteDesc'>
                            <img className='imgView' src={require('../../static/beian.jpg')}/>
                            <a className='siteDesc1' href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012120" target="_blank">{newCommon.copyRight3}</a>
                        </div>
                        
					</div>
				</Layout.Col>
		       <Layout.Col span="4"></Layout.Col>
		    </Layout.Row>
		);
	}
}

const mapStateToProps = (state) => ({
    common: state.getIn(['common', 'common'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        updateGIndex(index) {
        	dispatch(actions.updateActiveIndex(index));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);