import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import '../../../theme/light.scss';
import '../style.scss';

class Hornor extends PureComponent {

	render () {

		const {
			id,
			hornor
		} = this.props;
		let newHornor = hornor.toJS();
		if (!newHornor) {
			return null;
		}

		return (
			<Fragment>
		    	<div className='content hornorContentView' id={id}>
					<div className='titleView wow bounceInUp' data-wow-duration="2s">
						<h2 className='title'>{newHornor.title.zh}</h2>
						<p className='desc'>{newHornor.title.en}</p>
					</div>
				</div>
				{/*<div className='hornorView'>
					<div className='content'>
						<Row>
							{
								newHornor.numList.map((item, index) => {
									return (
										<Col xs={12} sm={4} className={index === 0 ? 'hornorTec wow bounceIn' : (index === 1 ? 'hornorPro wow bounceIn' : 'honorItem wow bounceIn')} data-wow-duration="2s" key={item.desc}>
											<span className='hornorNum'>{item.num}</span>
											<span>{item.desc}</span>
										</Col>
									)
								})
							}
						</Row>
					</div>
				</div>*/}
				<div className='content wow slideInUp'>
					<Row className='awardsView'>
						{
							newHornor.imgList.map((item, index) => {
								return (
									<Col key={item.url}>
										<img src={item.url} alt={item.url} className={index === 0 ? 'centerAwards' : 'sideAwards'}/>
									</Col>
								)
							})
						}
					</Row>
				</div>
			</Fragment>
		);
	}

}

const mapStateToProps = (state) => ({
    hornor: state.getIn(['aboutUs', 'hornor'])
})

export default connect(mapStateToProps, null)(Hornor);
