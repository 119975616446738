import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Carousel
} from 'react-bootstrap';
import '../../../theme/light.scss';

class Banner extends PureComponent {

    render () {
        const {
            id,
            list
        } = this.props;
        return (
            <Carousel id={id}>
                {
                    list.map((item, index) => {
                        let url = item.get('url');
                        return (
                            <Carousel.Item key={url}>
                                <img
                                  className="d-block w-100"
                                  src={url}
                                  alt={url}
                                />
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        );
  }

}

const mapStateToProps = (state) => ({
    list: state.getIn(['aboutUs', 'aboutbannerList'])
})

export default connect(mapStateToProps, null)(Banner);