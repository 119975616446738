import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from './store';
import { Row, Col } from 'react-bootstrap';
import Menu from '../../common/menu';
import './style.scss';
import { Layout } from 'element-react';

class Product extends PureComponent {

	constructor(props) {
		super(props);
		this.props.getProductData();

		if (this.props.location.search) {
			let str = this.props.location.search;
			str = parseInt(str.substring(1, str.length));
			this.props.handleItemClick(str);
		}
	}

	render () {
		const {
			list,
			activeIndex
		} = this.props;

		let newList = list.toJS();
		if (newList.length === 0) {
			return null;
		}
		let data = [];

		newList.forEach((item) => {
			data.push(item.name);
		})

		return (
			<Layout.Row type="flex" className="contentView">
	        	<Layout.Col span="1"></Layout.Col>
	        	<Layout.Col span="22">
				 <img src={require('../../static/proBanner.png')} alt='proBanner' className='proBanner'/>
				 <div className='content'>
		        	<Row>
		        		<Col xs={2} sm={2}>
							<Menu data={data}
								activeIndex={activeIndex}
								alt='product'/>
		        		</Col>
		        		<Col xs={10} sm={10}>
		        			{
		        				(activeIndex !== 0 && activeIndex !== 6) && <div className='contentArea'>
								<p className='prodTitle'>{data[activeIndex]}</p>
								<p className='prodContent'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{newList[activeIndex].desc}</p>
			        			<table className='prodTable'>
			        				<tbody>
				        				<tr className='prodTableTitle'>
				        					<td  width="38%">型号</td>
				        					<td width="62%">产品描述</td>
				        				</tr>
				        				{
				        					newList[activeIndex].proList.map((item, index) => {
				        						return (
													<tr className='prodTableItem' key={item.id}>
							        					<td><Link to={'/product/' + item.id}>{item.name}</Link></td>
							        					<td className='themeColorLeft'>{item.desc}</td>
							        				</tr>
				        						)
				        					})
				        				}
			        				</tbody>
			        			</table>
			        			{
			        				newList[activeIndex].scene && (
			        					<div>
					        				<div className='sceneTitle'>{newList[activeIndex].scene.name}</div>
						        			<Row>
						        				{
						        					newList[activeIndex].scene.list.map((item) => {
						        						return (
															<Col key={item.url} className='sceneView'>
																<img src={item.url} alt={item.url} className='sceneIcon'/>
																<div className='scenedd'>{item.name}</div>
									        				</Col>
						        						)
						        					})
						        				}
						        			</Row>
				        			</div>
				        		)}
		        			</div>
		        			}
		        			{
		        				activeIndex === 6 && <div className='contentArea'>内容正在整理中，敬请期待......</div>
		        			}
		        		</Col>
		        	</Row>
		         </div>
	         </Layout.Col>
		       <Layout.Col span="1"></Layout.Col>
		    </Layout.Row>
		);
	}

}

const mapStateToProps = (state) => ({
    list: state.getIn(['product', 'list']),
    activeIndex: state.getIn(['product', 'activeIndex'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getProductData() {
            dispatch(actions.getProductInfo());
        },
        handleItemClick(index) {
			dispatch(actions.handleItemClick(index));
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);