import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Banner from './Banner';
import '../../../theme/light.scss';
import '../style.scss';

class Introduce extends PureComponent {

	render () {
		const {
			id,
			introduce
		} = this.props;
		let newIntroduce = introduce.toJS();
		if (!newIntroduce) {
			return null;
		}

		return (
	        <div className='companyContent'>
	        	<div className='content' id={id}>
					<div className='titleView wow bounceInUp' data-wow-duration="2s">
						<h2 className='title'>{newIntroduce.title.zh}</h2>
						<p className='desc'>{newIntroduce.title.en}</p>
					</div>
					<Row className='companyView'>
						
						<Col xs={12} sm={12} className='companyDescView wow slideInRight'>
							<h2 className='companyTitle'>{newIntroduce.name}</h2>
							{
								newIntroduce.desc.map((item, index)=> {
								return (
									<div className='companyDesc' key={'companyDesc' + index}>{item}</div>
								)
							})
							}
						</Col>
            <Col xs={12} sm={12} className='companyIconView wow slideInLeft'>
            	
              <Banner id='banner'/>
            </Col>
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
    introduce: state.getIn(['aboutUs', 'introduce'])
})

export default connect(mapStateToProps, null)(Introduce);