import React, { PureComponent } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../style.scss';
import '../../../theme/light.scss';
import * as utils from '../../../common/utils';
import { Layout } from 'element-react';

class Product extends PureComponent {

	render () {
		const {
			id,
			product,
			handleItemClick
		} = this.props;
		let newProduct = product.toJS();
		if (newProduct.length === 0) {
			return null;
		}
		return (
			<Layout.Row type="flex" className="content">
	        	<Layout.Col span="2"></Layout.Col>
	        	<Layout.Col span="20">
		        <div id={id}>
					<div className='titleView wow bounceInUp' data-wow-duration="2s">
						<h2 className='title'>{newProduct.title.zh}</h2>
						<p className='desc'>{newProduct.title.en}</p>
					</div>
					<Row className='rowAround'>
						{
							newProduct.list.map((item, index) => {
								return (
									<Col xs={6} sm={2} className={index < 2 ? 'wow proList bounceInLeft' : 'wow proList bounceInRight'} key={item.url}>
										<Link to={{pathname:'/product', search: (index + 1 + '')}} onClick={handleItemClick.bind(this, (index + 1), 'product')}>
											<div className ='proTitle'>{item.name}</div>
											<Image src={item.url} thumbnail className='proIcon'/>
										</Link>
								    </Col>
								)
							})
						}
					</Row>
		        </div>
		        </Layout.Col>
		       <Layout.Col span="2"></Layout.Col>
		    </Layout.Row>
		);
	}
}

const mapStateToProps = (state) => ({
    product: state.getIn(['home', 'productList'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        handleItemClick(index, alt) {
        	 utils.handleNavItemClick(1, 'product');
		}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Product);