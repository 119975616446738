import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from '../store';
import { Link } from 'react-router-dom';
import * as utils from '../utils';
import '../../theme/light.scss';
import { Layout } from 'element-react';


class Header extends PureComponent {

	componentDidMount() {
		this.props.getCommonData();
	}

	render() {

		const {
			common,
			gIndex
		} = this.props;

		if (!common) {
			return null;
		}

		let newCommon = common.toJS();

		return (
			<Layout.Row type="flex" className="fixed-top bg-dark wrapper">
	        	<Layout.Col span="4"></Layout.Col>
	        	<Layout.Col span="16">
					<nav className="navbar navbar-expand-lg navbar-dark" id='navbar'>
						<a className="navbar-brand" href="/#" onClick={utils.handleNavItemClick.bind(this, 0)}>
							<img src={newCommon.logo} alt='logo' className='logo'/>
						</a>
				        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
				        	<span className="navbar-toggler-icon"></span>
				        </button>
						<div className="collapse navbar-collapse navRight" id="navbarCollapse">
							<ul className="navbar-nav">
								{
									newCommon.list.map((item, index) => {
										if (index === 0) {
											return (
												<li className={gIndex === index ? "nav-item active" : "nav-item"} onClick={utils.handleNavItemClick.bind(this, index)} key={item.link}>
													<a className="nav-link" href={item.url}>{item.link}</a>
												</li>
											)
										} else if (item.url.indexOf('#') > -1) {
											return (
												<Link to={item.url} key={item.link}>
													<li className={gIndex === index ? "nav-item active" : "nav-item"} onClick={utils.handleNavItemClick.bind(this, index, item.url.substring(2, item.url.length))}>
														<div className="nav-link">{item.link}</div>
													</li>
												</Link>
											)
										} else {
											return (
												<li className={gIndex === index ? "nav-item active" : "nav-item"} key={item.link} onClick={utils.handleNavItemClick.bind(this, index)}>
													<Link className="nav-link" to={item.url}>{item.link}</Link>
												</li>
											)
										}
									})
								}
							</ul>
						  </div>
					</nav>
	        	</Layout.Col>
	        	<Layout.Col span="4"></Layout.Col>
      		</Layout.Row>

		);
	}
}

const mapStateToProps = (state) => ({
    common: state.getIn(['common', 'common']),
    gIndex: state.getIn(['common', 'gIndex'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getCommonData() {
            dispatch(actions.getCommonInfo());
        },
        updateGIndex(index) {
        	dispatch(actions.updateActiveIndex(index));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);