import React, {
	PureComponent
} from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { actions } from './store';
import { actions as actionsG } from '../../common/store';
import Menu from '../../common/menu';
import './style.scss';
import { Layout } from 'element-react';

class Deatil extends PureComponent {

	constructor(props) {
	  super(props);

	  this.props.getProductData();


	  let str = this.props.location.pathname;
	  str = str.split('-');
	  str[0]= str[0].substring(str[0].length - 1, str[0].length);
	  this.props.handleItemClick(parseInt(str[0]));
	  this.props.updateGIndex(1);
	}

	render() {

		let newList = this.props.list.toJS();
		if (newList.length === 0) {
			return null;
		}
		let data = [];

		newList.forEach((item) => {
			data.push(item.name);
		})

		let id = this.props.match.params.id;
		id = id.split("-");

		let detail = newList[this.props.activeIndex].proList[Number(id[1] - 1)].detail || null;

		return (
			<Layout.Row type="flex" className="contentView">
	        	<Layout.Col span="1"></Layout.Col>
	        	<Layout.Col span="22">
				 <img src={require('../../static/proBanner.png')} alt='proBanner' className='proBanner'/>
				 <div className='content'>
		        	<Row>
		        		<Col xs={2} sm={2}>
							<Menu data={data}
								activeIndex={this.props.activeIndex}
								alt='product'/>
		        		</Col>
		        		<Col xs={10} sm={10}>
		        			<div className='contentArea'>
		        				<p className='prodTitle'>{newList[this.props.activeIndex].proList[Number(id[1] - 1)].name}</p>
								<p className='prodDesc'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{newList[this.props.activeIndex].proList[Number(id[1] - 1)].apply}</p>
								<div className='sceneTitle'>{detail && detail[0].name}</div>
								<ul className='prodChar'>
									{
										detail &&  detail[0].list.map((item) => {
											return (
												<li key={item}>{item}</li>
											)
										})
									}
								</ul>
								<div className='sceneTitle'>{detail && detail[1].name}</div>
								<p className='mg_top'>&nbsp;&nbsp;&nbsp;&nbsp;{detail && detail[1].desc}</p>
								<img src={detail && detail[1].url} alt={detail && detail[1].url} className='applyIcon'/>
								<div className='sceneTitle'>{detail && detail[2].name}</div>
								<Row>
									{
										detail && detail[2].list.map((item) => {
											return (
												<Col key={item.url} className='sceneView'>
													<img src={item.url} alt={item.url} className='sceneIcon'/>
													<div className='scenedd'>{item.name}</div>
						        				</Col>
											)
										})
									}
			        			</Row>
		        			</div>
		        		</Col>
		        	</Row>
		         </div>
	         	</Layout.Col>
		       <Layout.Col span="1"></Layout.Col>
		    </Layout.Row>
		)
	}
}

const mapStateToProps = (state) => ({
    list: state.getIn(['product', 'list']),
    activeIndex: state.getIn(['product', 'activeIndex'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getProductData() {
            dispatch(actions.getProductInfo());
        },
        handleItemClick(index) {
			dispatch(actions.handleItemClick(index));
		},
		updateGIndex(index) {
        	dispatch(actionsG.updateActiveIndex(index));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deatil);