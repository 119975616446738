import React, {
  PureComponent,
  Fragment
} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import {
  GloabelStyle,
} from './style';
import Header from './common/header';
import Footer from './common/footer';
import Home from './pages/home';
import AboutUs from './pages/aboutUs';
import LinkUs from './pages/linkUs';
import Product from './pages/product';
import Resolve from './pages/resolve';
import Environment from './pages/environment';
import Detail from './pages/product/detail';
import NewsDeatil from './pages/home/newsDetail';


class App extends PureComponent {

  render() {
    return (
        <Fragment>
            <GloabelStyle/>
            <Provider store={store}>
                <Fragment>
                    <BrowserRouter>
                        <Fragment>
                          <Header/>
                          <Route path='/' exact component={Home}></Route>
                          <Route path='/product:id?' exact component={Product}></Route>
                          <Route path='/resolve:id?' exact component={Resolve}></Route>
                          <Route path='/aboutUs' exact component={AboutUs}></Route>
                          <Route path='/environment' exact component={Environment}></Route>
                          <Route path='/linkUs' exact component={LinkUs}></Route>
                          <Route path='/product/:id' exact component={Detail}></Route>
                          <Route path='/news/:id' exact component={NewsDeatil}></Route>
                          <Footer/>
                        </Fragment>
                    </BrowserRouter>
                </Fragment>
            </Provider>
        </Fragment>
    );
  }
}

export default App;