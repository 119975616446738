import React, {
	PureComponent
} from 'react';
import { connect } from 'react-redux';
import { actions } from './store';
import '../../theme/light.scss';
import { Layout } from 'element-react';

class NewsDeatil extends PureComponent {

	constructor(props) {
	  super(props);

	  this.props.getHomeData();
	}

	render() {
		let newData = this.props.data.toJS();

		if (newData.length === 0) {
			return null;
		}

		let detail = newData.list[Number(this.props.match.params.id - 1)].detail;

		return (
			<Layout.Row type="flex" className="content contentView">
	        	<Layout.Col span="4"></Layout.Col>
	        	<Layout.Col span="16">
					<div className='newsDetailArea' dangerouslySetInnerHTML={{ __html: detail}}></div>
	        	</Layout.Col>
		       <Layout.Col span="4"></Layout.Col>
		    </Layout.Row>
		)
	}
}

const mapStateToProps = (state) => ({
    data: state.getIn(['home', 'newsList'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getHomeData() {
            dispatch(actions.getHomeInfo());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDeatil);