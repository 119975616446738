import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'element-react';

class AboutUs extends PureComponent {

	render() {

		const {
			id,
			about
		} = this.props;
		let newAbout = about.toJS();
		if (newAbout.length === 0) {
			return null;
		}
		return (
			<Layout.Row type="flex" className="aboutUsContent">
	        	<Layout.Col span="4"></Layout.Col>
	        	<Layout.Col span="16">
			        <div id={id}>
						<div className='content'>
							<div className='titleView wow bounceInUp' data-wow-duration="2s">
								<h2 className='title'>{newAbout.title.zh}</h2>
								<p className='desc'>{newAbout.title.en}</p>
							</div>
							{
								newAbout.desc.map((item) => {
									return (
										<div className='aboutDesc' key={item}>{item}</div>
									)
								})
							}
			                {/*<Row className='wow bounce'>
								{
			                		newAbout.list.map((item, index) => {
			                			return (
			                				<Col xs={12} sm={4} key={item.desc}>
												<div className='aboutNum'>{item.num}</div>
												<div>{item.desc}</div>
						                	</Col>
			                			)
			                		})
			                	}
			                </Row>*/}
						</div>
					</div>
				</Layout.Col>
		       <Layout.Col span="4"></Layout.Col>
		    </Layout.Row>
		);
	}

}

const mapStateToProps = (state) => ({
    about: state.getIn(['home', 'aboutList'])
})

export default connect(mapStateToProps, null)(AboutUs);