import { fromJS } from 'immutable';
import { actionTypes } from './';

const defaultState = fromJS({
	aboutUrl: '',
	introduce: '',
	culture: '',
	hornor: '',
  aboutbannerList: []
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case actionTypes.GET_ABOUTUS_DATA:
			return state.merge({
				aboutUrl: fromJS(action.data.aboutUrl),
				introduce: fromJS(action.data.introduce),
				culture: fromJS(action.data.culture),
				hornor: fromJS(action.data.hornor),
        aboutbannerList: fromJS(action.data.aboutbannerList),
			});
		default:
			return state;
	}
}