export function hasClass (ele, cls) {
 	return ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

export function removeClass (ele, cls) {
	if (hasClass(ele, cls)) {
		var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
		ele.className = ele.className.replace(reg, " ");
 	}
}

export function handleNavItemClick(index, id) {
	const {
		common,
		updateGIndex
	} = this.props;

	updateGIndex(index);
	let ele = document.getElementById('navbarCollapse');
	removeClass(ele, 'show');
	if (common.toJS().list[index].url.indexOf('#') > -1 && index > 0) {
		setTimeout(() => {
			let navHeight = document.getElementById('navbar').clientHeight;
			let target = document.getElementById(id).parentNode.parentNode;
			window.scrollTo(0,target.offsetTop - navHeight);
		}, 100);
	} else {
		window.scrollTo(0, 0);
	}
}

export function toTop() {
	window.scrollTo(0, 0);
}