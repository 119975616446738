import { fromJS } from 'immutable';
import { actionTypes } from './';

const defaultState = fromJS({
	link: ''
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case actionTypes.GET_LINKUS_DATA:
			return state.merge({
				link: fromJS(action.data)
			});
		default:
			return state;
	}
}