import axios from 'axios';
import WOW from 'wowjs';
import { actionTypes } from './';

export const getCommonData = (data) => {
	let linkList = [];
	let str = window.location.href;
	let gIndex = 0;
	data.list.forEach((item, index) => {
		if (item.url.indexOf('#') > -1 ) {
			linkList.push(item.url.substring(2, item.url.length));
		} else {
			linkList.push(item.url.substring(1, item.url.length));
		}
	})
	linkList.forEach((item, index) => {
		if (item && str.indexOf(item) > -1) {
			 gIndex = index;
		}
	})
	let newData = Object.assign(data);
	newData.gIndex = gIndex;
	return {
		type: actionTypes.GET_COMMON_DATA,
		data,
		gIndex
	}
}

export const updateGIndex = (index) => ({
	type: actionTypes.UPDATE_ACTIVE_INDEX,
	index
})

export const getCommonInfo = () => {
	return (dispatch) => {
		axios.get('/api/common.json').then((res) => {
			const result = res.data.data;
			const action = getCommonData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('common request error');
		})
	}
}

export const updateActiveIndex = (index) => {
	return (dispatch) => {
		const action = updateGIndex(index);
		dispatch(action);
	}
}