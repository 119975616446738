import React, { PureComponent } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../style.scss';
import * as utils from '../../../common/utils';
import { Layout } from 'element-react';

class Resolve extends PureComponent {

	render () {

		const {
			id,
			resolve,
			handleItemClick
		} = this.props;
		let newResolve = resolve.toJS();
		if (newResolve.length === 0) {
			return null;
		}
		return (
			<Layout.Row type="flex" className="resolveContent">
	        	<Layout.Col span="2"></Layout.Col>
	        	<Layout.Col span="20">
		        <div className='content' id={id}>
					<div className='titleView wow bounceInUp' data-wow-duration="2s">
						<h2 className='title'>{newResolve.title.zh}</h2>
						<p className='desc'>{newResolve.title.en}</p>
					</div>
					<Row className='wow bounceIn rowAround' data-wow-duration="2s">

						{
							newResolve.list.map((item, index) => {
								return (
									 <Col xs={12} sm={6} key={item.url}>
								    	<Link to={{pathname:'/resolve', search: (index + 1 + '')}} className='resolveView' onClick={handleItemClick.bind(this, (index + 1), 'product')}>
								    		<Image src={item.url} className='resolveIcon'/>
											<div className ='resolveTitle'>{item.title}</div>
											<div className ='resolveDesc'>{item.desc}</div>
								    	</Link>
								    </Col>
								)
							})
						}

					</Row>
		        </div>
	        </Layout.Col>
		       <Layout.Col span="2"></Layout.Col>
		    </Layout.Row>
		);
	}
}

const mapStateToProps = (state) => ({
    resolve: state.getIn(['home', 'resolveList'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        handleItemClick(index, alt) {
        	 utils.handleNavItemClick(1, 'resolve');
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resolve);