import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from './store';
import { Row, Col } from 'react-bootstrap';
import '../../theme/light.scss';
import './style.scss';
import { Layout } from 'element-react';

class LinkUs extends PureComponent {

	componentDidMount() {
        this.props.getLinkUsData();
    }

	render () {

		const {
			link
		} = this.props;

		if (!link) {
			return null;
		}

		let newLink = link.toJS();

		return (
			<Layout.Row type="flex" className="contentView">
	        	<Layout.Col span="4"></Layout.Col>
	        	<Layout.Col span="16">
	        		<img src={newLink.linkUsUrl} alt={newLink.linkUsUrl} className='aboutUsBg'/>
	         		<div className='content wow bounceInDown' data-wow-duration="2s">
		         	<Row>
						<Col xs={12} sm={4} className='linkBg'>
							<img src={newLink.linkBg} alt={newLink.linkBg} className='icon'/>
						</Col>
						<Col xs={12} sm={8} className='linkView'>
							<div className='linkTitleView'>
								<span className='linkTitle'>{newLink.linkTitle}</span>
							</div>
							<div className='company'>{newLink.name}</div>
							<div><span className='linkDD'>{newLink.addrDD}</span>{newLink.addrDT}</div>
							{
								newLink.list.map((item, index) => {
									return (
										<Row key={index}>
												{
													item.subList.map((itm, i) => {
														return (
															<Col key={itm.name}>
																<span className='linkDD'>{itm.name}</span>{itm.desc}
															</Col>
														)
													})
												}
										</Row>
									)
								})
							}
						</Col>
					</Row>
					</div>
	         	</Layout.Col>
		       <Layout.Col span="4"></Layout.Col>
		    </Layout.Row>
		);
	}

}

const mapStateToProps = (state) => ({
    link: state.getIn(['linkUs', 'link'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getLinkUsData() {
            dispatch(actions.getLinkUsInfo());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkUs);