import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { actions } from './store';
import Banner from './components/Banner';
import Product from './components/Product';
import Resolve from './components/Resolve';
import AboutUs from './components/AboutUs';
import '../../theme/light.scss';


class Home extends PureComponent {

    componentDidMount() {
        this.props.getHomeData();
    }

    render() {
        return (
            <div>
                <Banner id='banner'/>
                <Product id='product'/>
                <Resolve id='resolve'/>
                <AboutUs id='aboutUs'/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getHomeData() {
            dispatch(actions.getHomeInfo());
        }
    }
}

export default connect(null, mapDispatchToProps)(Home);