import React, { PureComponent }  from 'react';
import { connect } from 'react-redux';
import { actions } from './store';
import Introduce from './components/Introduce';
import Culture from './components/Culture';
import Hornor from './components/Hornor';
import '../../theme/light.scss';
import './style.scss';
import { Layout } from 'element-react';

class AboutUs extends PureComponent {

	componentDidMount() {
        this.props.getAboutUsData();
    }

	render () {

		const {
			aboutUrl
		} = this.props;

		if (!aboutUrl) {
			return null;
		}

		return (
			<Layout.Row type="flex" className="contentView">
	        	<Layout.Col span="4"></Layout.Col>
	        	<Layout.Col span="16">
	        		<img src={aboutUrl} alt='aboutUsBg' className='aboutUsBg'/>
	        		<Introduce id='introduce'/>
	        		<Culture id='culture'/>
	        		<Hornor id='honor'/>
	         	</Layout.Col>
		       <Layout.Col span="4"></Layout.Col>
		    </Layout.Row>
		);
	}
}

const mapStateToProps = (state) => ({
    aboutUrl: state.getIn(['aboutUs', 'aboutUrl'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getAboutUsData() {
            dispatch(actions.getAboutUsInfo());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);