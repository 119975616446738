import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import '../../../theme/light.scss';
import '../style.scss';

class Culture extends PureComponent {

	render () {

		const {
			id,
			culture
		} = this.props;
		let newCulture = culture.toJS();
		if (!newCulture) {
			return null;
		}

		return (
	    	<div className='content' id={id}>
				<div className='titleView wow bounceInUp' data-wow-duration="2s">
					<h2 className='title'>{newCulture.title.zh}</h2>
					<p className='desc'>{newCulture.title.en}</p>
				</div>
				<Row className='wow bounceInUp'>
					{
						newCulture.list.map((item, index) => {
							return (
								<Col xs={12} sm={4} className='companyIconView' key={item.url}>
									<img src={item.url} alt={item.url} className='cultureIcon'/>
									<div className='cultureTitle'>{item.name}</div>
									<div className='cultureDesc'>{item.desc}</div>
								</Col>
							)
						})
					}
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
    culture: state.getIn(['aboutUs', 'culture'])
})

export default connect(mapStateToProps, null)(Culture);