import { fromJS } from 'immutable';
import { actionTypes } from './';

const defaultState = fromJS({
	list: [],
	activeIndex: 1
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case actionTypes.GET_RESOLVE_DATA:
			return state.merge({
				list: fromJS(action.data.menuList)
			});
		case actionTypes.UPDATE_RESOLVE_ACTIVE_INDEX:
			return state.merge({
				activeIndex: fromJS(action.index)
			})
		default:
			return state;
	}
}