import axios from 'axios';
import WOW from 'wowjs';
import 'animate.css';
import { actionTypes } from './';

export const getProductData = (data) => ({
	type: actionTypes.GET_PRODUCT_DATA,
	data
})

export const handleItemClick = (index) => ({
	type: actionTypes.UPDATE_MENU_ACTIVE_INDEX,
	index
})

export const getProductInfo = () => {
	return (dispatch) => {
		axios.get('/api/product.json').then((res) => {
			const result = res.data.data;
			const action = getProductData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('product request error');
		})
	}
}

