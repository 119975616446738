import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import '../../theme/light.scss';
import { connect } from 'react-redux';
import { actions as actionProduct } from '../../pages/product/store';
import { actions as actionResolve } from '../../pages/resolve/store';
import { actions as actionEnvironment } from '../../pages/environment/store';

class Menu extends PureComponent {

	render() {

		const {
			data,
			handleItemClick,
			activeIndex,
			alt
		} = this.props;
		return (
			<ul className='menuArea'>
				{
					data.map((item, index) => {
						if (index === 0) {
							return (
								<li className='titleName' key={item}>{item}</li>
							)
						} else {
							return (
								<li className={index === activeIndex ? 'titleItemActive' : 'titleItem'} key={item} onClick={handleItemClick.bind(this, index, alt)}><Link to={'/' + alt}>{item}</Link></li>
							)
						}
					})
				}
			</ul>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleItemClick(index, alt) {
        	if (alt === 'product') {
				dispatch(actionProduct.handleItemClick(index));
        	} else if (alt === 'resolve') {
				dispatch(actionResolve.handleItemClick(index));
        	} else if (alt === 'environment') {
        		dispatch(actionEnvironment.handleItemClick(index));
        	}
		}
    }
}

export default connect(null, mapDispatchToProps)(Menu);