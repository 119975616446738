import React, {
	PureComponent
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from './store';
import { Row, Col } from 'react-bootstrap';
import Menu from '../../common/menu';
import '../product/style.scss';
import * as utils from '../../common/utils';
import { Layout } from 'element-react';

class Resolve extends PureComponent {

	constructor(props) {
		super(props);

	    this.props.getResolveData();

		if (this.props.location.search) {
			let str = this.props.location.search;
			str = parseInt(str.substring(1, str.length));
			this.props.handleItemClick(str);
		}

	}

	render() {

		const {
			list,
			activeIndex
		} = this.props;

		let newList = list.toJS();
		if (newList.length === 0) {
			return null;
		}
		let data = [];

		newList.forEach((item) => {
			data.push(item.name);
		})

		let contentList = newList[activeIndex].list;

		return (
			<Layout.Row type="flex" className="contentView">
	        	<Layout.Col span="1"></Layout.Col>
	        	<Layout.Col span="22">
				 <img src={require('../../static/proBanner.png')} alt='proBanner' className='proBanner'/>
				 <div className='content'>
		        	<Row>
		        		<Col xs={2} sm={2}>
							<Menu data={data}
								activeIndex={activeIndex}
								alt='resolve'/>
		        		</Col>
		        		<Col xs={10} sm={10}>
		        			{ (activeIndex === 2 || activeIndex === 1 ) && <div className='contentArea'>
		        				<p className='prodTitle'>{data[activeIndex]}</p>
								<div className='sceneTitle resolveSceneTitle'>{contentList[0].name}</div>
								<p className='mg_top'>&nbsp;&nbsp;&nbsp;&nbsp;{contentList[0].desc}</p>
								<div className='sceneTitle'>{contentList[1].name}</div>
								<ul className='prodChar'>
									{
										contentList[1].list.map((item) => {
											return (
												<li key={item}>{item}</li>
											)
										})
									}
								</ul>
								<div className='sceneTitle'>{contentList[2].name}</div>
								<p className='mg_top'>&nbsp;&nbsp;&nbsp;&nbsp;{contentList[2].desc}</p>
								<img src={contentList[2].url} alt={contentList[2].url} className='applyIcon'/>
								<div className='relProTitle'>{contentList[3].name}</div>
								<ul className='prodChar'>
									{
										contentList[3].list.map((item, index) => {
											return (
												<li key={item.name + item.index}>
													<span>{item.name}</span>
													{
														item.list.map((item) => {
															return (
																<Link to={'/product/' + item.id}
																	dangerouslySetInnerHTML={{ __html: item.name}}
																	key={item.name}
																	className='themeColor proItem'
																	onClick={utils.toTop}></Link>
															)
														})
													}
												</li>
											)
										})
									}
								</ul>
		        			</div>}
		        			{
		        				activeIndex === 3 && <div className='contentArea'>内容正在整理中，敬请期待......</div>
		        			}
		        		</Col>
		        	</Row>
		         </div>
	         	</Layout.Col>
		       <Layout.Col span="1"></Layout.Col>
		    </Layout.Row>
		)
	}
}

const mapStateToProps = (state) => ({
    list: state.getIn(['resolve', 'list']),
    activeIndex: state.getIn(['resolve', 'activeIndex'])
})

const mapDispatchToProps = (dispatch) => {
    return {
        getResolveData() {
            dispatch(actions.getResolveInfo());
        },
        handleItemClick(index) {
			dispatch(actions.handleItemClick(index));
		}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resolve);