import axios from 'axios';
import WOW from 'wowjs';
import 'animate.css';
import { actionTypes } from './';

export const getResolveData = (data) => ({
	type: actionTypes.GET_RESOLVE_DATA,
	data
})

export const handleItemClick = (index) => ({
	type: actionTypes.UPDATE_RESOLVE_ACTIVE_INDEX,
	index
})

export const getResolveInfo = () => {
	return (dispatch) => {
		axios.get('/api/resolve.json').then((res) => {
			const result = res.data.data;
			const action = getResolveData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('resolve request error');
		})
	}
}

