import axios from 'axios';
import 'animate.css';
import WOW from 'wowjs';
import { actionTypes } from './';


export const getAboutUsData = (data) => ({
	type: actionTypes.GET_ABOUTUS_DATA,
	data
})

export const getAboutUsInfo = () => {
	return (dispatch) => {
		axios.get('/api/aboutUs.json').then((res) => {
			const result = res.data.data;
			const action = getAboutUsData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('aboutUs request error');
		})
	}
}