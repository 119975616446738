import { fromJS } from 'immutable';
import { actionTypes } from './';

const defaultState = fromJS({
	bannerList: [],
	productList: [],
	resolveList: [],
	newsList: [],
	advantageList: [],
	aboutList: []
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case actionTypes.GET_HOME_DATA:
			return state.merge({
				bannerList: fromJS(action.data.bannerList),
				productList: fromJS(action.data.productList),
				resolveList: fromJS(action.data.resolveList),
				newsList: fromJS(action.data.newsList),
				advantageList: fromJS(action.data.advantageList),
				aboutList: fromJS(action.data.aboutList)
			});
		default:
			return state;
	}
}