import { combineReducers } from 'redux-immutable';
import { reducer as homeReducer } from '../pages/home/store';
import { reducer as aboutUsReducer } from '../pages/aboutUs/store';
import { reducer as linkUsReducer } from '../pages/linkUs/store';
import { reducer as productReducer } from '../pages/product/store';
import { reducer as resolveReducer } from '../pages/resolve/store';
import { reducer as environmentReducer } from '../pages/environment/store';
import { reducer as commonReducer } from '../common/store';

const reducer = combineReducers({
	home: homeReducer,
	product: productReducer,
	resolve: resolveReducer,
	aboutUs: aboutUsReducer,
	linkUs: linkUsReducer,
	common: commonReducer,
	environment: environmentReducer
})

export default reducer;