import { fromJS } from 'immutable';
import { actionTypes } from './';

const defaultState = fromJS({
	common: '',
	gIndex: 0
});

export default (state = defaultState, action) => {
	switch(action.type) {
		case actionTypes.GET_COMMON_DATA:
			return state.merge({
				common: fromJS(action.data),
				gIndex: fromJS(action.data.gIndex)
			});
		case actionTypes.UPDATE_ACTIVE_INDEX:
			return state.merge({
				gIndex: fromJS(action.index)
			});
		default:
			return state;
	}
}