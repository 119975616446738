import axios from 'axios';
import 'animate.css';
import WOW from 'wowjs';
import { actionTypes } from './';


export const getHomeData = (data) => ({
	type: actionTypes.GET_HOME_DATA,
	data
})

export const getHomeInfo = () => {
	return (dispatch) => {
		axios.get('/api/home.json').then((res) => {
			const result = res.data.data;
			const action = getHomeData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('home request error');
		})
	}
}