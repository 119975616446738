import axios from 'axios';
import 'animate.css';
import WOW from 'wowjs';
import { actionTypes } from './';

export const getLinkUsData = (data) => ({
	type: actionTypes.GET_LINKUS_DATA,
	data
})

export const getLinkUsInfo = () => {
	return (dispatch) => {
		axios.get('/api/linkUs.json').then((res) => {
			const result = res.data.data;
			const action = getLinkUsData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('linkUs request error');
		})
	}
}