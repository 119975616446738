import axios from 'axios';
import WOW from 'wowjs';
import 'animate.css';
import { actionTypes } from './';

export const getEnvironmentData = (data) => ({
	type: actionTypes.GET_ENVIRONMENT_DATA,
	data
})

export const handleItemClick = (index) => ({
	type: actionTypes.UPDATE_ENVIRONMENT_ACTIVE_INDEX,
	index
})

export const getEnvironmentInfo = () => {
	return (dispatch) => {
		axios.get('/api/environment.json').then((res) => {
			const result = res.data.data;
			const action = getEnvironmentData(result);
			dispatch(action);
			new WOW.WOW({live: false}).init();
		}).catch(() => {
			console.warn('environment request error');
		})
	}
}

